/* stylesheets index.html / wallets.html */

body{
    font-family: 'Roboto', sans-serif;
}

h1{
    color: #0f1539;
    font-family: 'Raleway', 'Regular', Helvetica, Arial, sans-serif;
    font-weight: lighter;
}

h2{
    color:white;
    font-family: 'Raleway', 'Regular', Helvetica, Arial, sans-serif;
    font-weight: lighter;
}

.navbar{
    background-color: #0f1539;
    opacity: 0.9;
    position: fixed;
    width:100%;
    z-index:1000;
}

.btn-cover,.btn-cover:hover, .btn-cover::selection{
    color: white;
    background-image: linear-gradient(to right, #101539 , #2a3c69);
    border-radius:20px; border: 0px;
    box-shadow: rgba(65, 45, 178, 0.24) 1px 13px 35px;
}

.btn-cover-white {
    background: white !important;
    color:black !important;
}

.wallets{
    background-color: #0f1539;
    color:white;
}

.communication-footer{
    background-color: #0f1539;
    color:white;
}

#wallet-img{
    width: 70%;
}

.vertical-align {
    display: flex;
    align-items: center;
}

@media (min-width: 768px){
    .separator{
        background-image: url(/assets/images/curve.png);
        height: 150px;
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: 40px;
        margin-bottom: 10px;
    }
  }

  @media (max-width: 767px) {
    .separator{
        background-image: url(/assets/images/curve.png);
        height: 50px;
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: 40px;
        margin-bottom: 20px;
    }
  }

.form-signin, .form-signup {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
}

.btn-signin, .btn-signin:hover{
    background-color: #0F1539;
    color: white;
    box-shadow: rgba(65, 45, 178, 0.24) 1px 13px 35px;
}

a{
    color: #1F2549;
    text-decoration: none;
}
a:hover{
    color: #C99705;
    text-decoration: none;
}

.btn-panel-menu{
    border-color: #0F1539;
    color: #0F1539;
}

.btn-panel-menu:hover{
    border-color: #0F1539;
    background-color: #0F1539;
    color: white;
}

.wallet-link {
    color:white !important;
    text-decoration:underline;
}

.copyleft {
  display:inline-block;
  transform: rotate(180deg);
}

.language-list {
  list-style-type: none;
  position:fixed;
  top: 1rem;
  background: gainsboro;
  color:white;
  left: 1rem;
  padding: 0.2rem;
  cursor:pointer;
}

#language-btn {
  cursor: pointer;
}

.communication{
    max-width: 750px;
    margin: auto;
}

.socialIcon{
    width: 80px;
}

#communication-img{
    width: 70%;
}


.main-header img {
    float: right;
    margin: 0px;
}

.main-header {
    padding: 26px 0 30px;
    margin-bottom: 20px;
    background-color: #0f1539;
    text-align: left;
    color: #f0f0f0;
}

.footer {
    padding: 26px 0;
    margin: 20px 0 40px;
}

.infobox {
  padding:0.5em;
}

.main {
  padding-top: 100px;
  padding-left: 30px;
  padding-right: 30px;
  hyphens: auto;
}

.download_pdf {
  text-align: right;
}

.binaries img {
  width:32px;
}

.sha256sum {
  font-family: 'Courier New';
  color: goldenrod;
  font-size: 0.8em;
}
